import React from "react";
import { Link, graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import LazyLoad from "react-lazyload";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Slideshow from "../components/slideshow";
import FaqSection from "../components/faq-section";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FeaturedOffer from "../components/featured-offer";
import OffersSlider from "../components/offers-slider";
import GradientSection from "../components/gradient-section";

const OffersTemplate = ({ data, pageContext: context }) => {
  const { title, main_title, subtitle, content } = data.contentfulPage;
  const allPages = data.allContentfulPage.nodes;

  function getParentSlug() {
    return allPages.find((page) => page.category === "Offres").slug;
  }

  return (
    <Layout>
      <Seo title={title} description={main_title} />
      <section className="section section-white">
        <div className="introduction introduction-clouds">
          <div className="introduction-container">
            <div className="introduction-inner">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <p className="eyebrow">{title}</p>
                    <h1>{main_title}</h1>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-gradient overflow-hidden">
        <div className="container">
          {data.contentfulOffre && data.contentfulOffre.active !== false ? (
            <FeaturedOffer offer={data.contentfulOffre} />
          ) : data.contentfulHotel.offer ? (
            <FeaturedOffer offer={data.contentfulHotel.offer} />
          ) : (
            ""
          )}
          <div
            className={
              (data.contentfulOffre && data.contentfulOffre.active !== false) ||
              data.contentfulHotel.offer
                ? "row mt-16 mt-md-30"
                : "row"
            }
          >
            <div className="col-xl-3 pr-xl-10">
              <h2 className="heading mt-xl-6">{subtitle}</h2>
              {content?.map((block) => {
                  switch (block.__typename) {
                    case "ContentfulTexte":
                      return (
                        <section class="mb-6 mb-xl-0">
                          {block.content && renderRichText(block.content)}
                        </section>
                      );

                    case "ContentfulDiaporama":
                      return (
                        <Slideshow
                          title={block.title}
                          text={block.text}
                          slides={block.slides}
                          key={block.contentful_id}
                        />
                      );

                    case "ContentfulFaqRubrique":
                      return (
                        <FaqSection
                          title={block.title}
                          questions={block.questions}
                          key={block.contentful_id}
                        />
                      );

                    case "ContentfulGradientSection":
                      return ( 
                        <GradientSection block={block}/>
                      );

                    default:
                      return null;
                  }
                })}
            </div>
            <div className="col-8 col-sm-10 col-lg-12 col-xl-9">
              <LazyLoad>
                <OffersSlider />
                <div className="grid grid-offers d-none d-lg-grid">
                  {data.allContentfulOffre.nodes.map((node, i) => {
                    const {
                      url: iconUrl,
                      details: {
                        image: { width: iconWidth, height: iconHeight },
                      },
                    } = node.icon.file;

                    return (
                      <Link
                        className={`offer offer-${node.color}`}
                        to={`/${context.locale}/${getParentSlug()}/${
                          node.slug
                        }/`}
                        key={i}
                      >
                        <div className="offer-heading">
                          {node.discount && <p>{node.discount}</p>}
                          {node.icon && (
                            <img
                              src={iconUrl}
                              width={iconWidth}
                              height={iconHeight}
                              alt={node.title}
                            />
                          )}
                        </div>
                        <div className="offer-description">
                          <h3>{node.title}</h3>
                          <p>{node.short_description}</p>
                          <p className="link">
                            <FormattedMessage id="more" />
                          </p>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OffersTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!, $hotel: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      address
      phone
      booking_id
      place_id
      business_url
      offer {
        title
        slug
        color
        short_description
        type
        discount
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      hero_image {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      slug
      spirit {
        slug
      }
      seo_site_hotel_meta_description
    }
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
      subtitle
      content {
        ... on ContentfulTexte {
          contentful_id
          __typename
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              ... on ContentfulBanniereDeReservation {
                contentful_id
                __typename
                title
              }
            }
          }
        }
        ... on ContentfulDiaporama {
          contentful_id
          __typename
          title
          text
          slides {
            ... on ContentfulActivite {
              contentful_id
              __typename
              title
              slug
              thumbnail {
                fluid(maxHeight: 320, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            ... on ContentfulService {
              contentful_id
              __typename
              title
              slug
              thumbnail {
                fluid(maxHeight: 320, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
        ... on ContentfulFaqRubrique {
          contentful_id
          __typename
          title
          questions {
            contentful_id
            question
            answer {
              raw
            }
          }
        }
        ... on ContentfulGradientSection {
          id
          title
          __typename
          contentGradient: content {
            ... on ContentfulImageTexte {
              id
              title
              image {
                fluid(maxHeight: 650, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
              }
              contentText {
                raw
              }
              imageDisplay
            }
            ... on ContentfulListeDePartenaires {
              id
              title
              partners {
                id
                description
                file {
                  fileName
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              bottomText
              bottomTitle
            }
          }
        }
      }
    }
    contentfulOffre(
      hotel: { elemMatch: { slug: { eq: $hotel } } }
      type: { eq: "Offre ponctuelle" }
      node_locale: { eq: $locale }
    ) {
      title
      slug
      color
      short_description
      type
      discount
      icon {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      active
    }
    allContentfulOffre(
      filter: {
        hotel: { elemMatch: { slug: { eq: $hotel } } }
        type: { eq: "Offre permanente" }
        node_locale: { eq: $locale }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        title
        slug
        color
        short_description
        type
        discount
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;
