import React from "react";
import { useIntl } from "react-intl";

import Previous from "./previous";

const PreviousButton = ({ onClick }) => {
  const intl = useIntl();

  return (
    <button
      className="slider-arrow slider-arrow-previous"
      onClick={onClick}
      aria-label={intl.formatMessage({ id: "prev" })}
    >
      <Previous />
    </button>
  );
};

export default PreviousButton;
