import React from "react";
import { useIntl } from "react-intl";

import Next from "./next";

const NextButton = ({ onClick }) => {
  const intl = useIntl();

  return (
    <button
      className="slider-arrow slider-arrow-next"
      onClick={onClick}
      aria-label={intl.formatMessage({ id: "next" })}
    >
      <Next />
    </button>
  );
};

export default NextButton;
