import React from "react";

const Previous = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7071 13.2929C24.0976 13.6834 24.0976 14.3166 23.7071 14.7071L18.4142 20L23.7071 25.2929C24.0976 25.6834 24.0976 26.3166 23.7071 26.7071C23.3166 27.0976 22.6834 27.0976 22.2929 26.7071L16.2929 20.7071C15.9024 20.3166 15.9024 19.6834 16.2929 19.2929L22.2929 13.2929C22.6834 12.9024 23.3166 12.9024 23.7071 13.2929Z"
      fill="currentColor"
    />
  </svg>
);

export default Previous;
