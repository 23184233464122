import React from "react";
import Img from "gatsby-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { useIntl } from "react-intl";
import BookModalHotel from "../components/book-hotel-modal";

const GradientSection = ({ block }) => {
    const intl = useIntl();
    return (
        <div>    
            { block.contentGradient.length>1 && (
                <section
                    className="section section-gradient"
                    key={block.id}
                >
                    <div className="container">
                    {block.contentGradient.map((content) => (
                        <div
                        className={`columns columns-full no-reverse-even ${
                            content.imageDisplay === "Droite"
                            ? "columns-reverse"
                            : ""
                        }`}
                        key={content.id}
                        >
                        <div className="columns-item columns-item-image columns-item-image-full">
                            {content.image &&
                            <Img
                                fluid={{
                                ...content.image.fluid,
                                aspectRatio: 460 / 650,
                                }}
                                alt={content.image.title}
                            />
                            }
                        </div>
                        <div className="columns-item columns-item-text columns-item-text-full">
                            <h3 class="h2">{content.title}</h3>
                            {content.contentText && renderRichText(content.contentText)}
                        </div>
                        </div>
                    ))}
                    </div>
                </section> 
            )} 
            {block.contentGradient.length<=1 && (
                <div key={block.id}>
                    <section className="section section-gradient pb-12">
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-lg-8">
                            {block.contentGradient.map((content) => (
                            <div key={content.id}>
                                <h3 class="h2">{content.title}</h3>
                                {content.partners &&
                                content.partners.map((partner) => (
                                    <div key={partner.id} className="row mb-10">
                                    <div className="col-lg-4 col-xl-3 mb-4 pr-8">
                                        <img
                                        src={partner.file.url}
                                        width={partner.file.details.image.width}
                                        height={partner.file.details.image.height}
                                        alt={partner.file.fileName}
                                        />
                                    </div>
                                    <div className="col-lg-8 col-xl-9">
                                        <p>{partner.description}</p>
                                    </div>
                                    </div>
                                ))}
                            </div>
                            ))}
                        </div>
                        </div>
                    </div>
                    </section>
                    <section className="section section-blue text-center">
                    <div className="container">
                        <div className="row justify-content-center">
                        {block.contentGradient.map((content) => (
                            <div className="col-md-6" key={block.id}>
                            <h2>{content.bottomTitle}</h2>
                            <p>{content.bottomText}</p>
                            <p class="mt-8 mt-md-10">
                                <BookModalHotel
                                text={intl.formatMessage({ id: "book-hotel" })}
                                />
                            </p>
                            </div>
                        ))}
                        </div>
                    </div>
                    </section>
                </div>
            )}
        </div>      
    )
}

export default GradientSection;