import React from "react";

const Next = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2929 26.7071C15.9024 26.3166 15.9024 25.6834 16.2929 25.2929L21.5858 20L16.2929 14.7071C15.9024 14.3166 15.9024 13.6834 16.2929 13.2929C16.6834 12.9024 17.3166 12.9024 17.7071 13.2929L23.7071 19.2929C24.0976 19.6834 24.0976 20.3166 23.7071 20.7071L17.7071 26.7071C17.3166 27.0976 16.6834 27.0976 16.2929 26.7071Z"
      fill="currentColor"
    />
  </svg>
);

export default Next;
