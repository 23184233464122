import { useIntl } from "react-intl";
import React, { useState, useContext } from "react";
import Close from "./close";
import Search from "./search";
import Context from "./context";

const BookModalHotel = ({ text }) => {
  const intl = useIntl();
  const { data } = useContext(Context);
  const [search, setSearch] = useState("");

  function toggleSearch() {
    setSearch(search === "" ? " active" : "");
  }

  return (
    <div className={`modal modal-search${search}`}>
      <button className="button" onClick={toggleSearch}>
        {text}
      </button>
      <div className="modal-content">
        <button
          className="modal-close"
          aria-label={intl.formatMessage({ id: "close" })}
          onClick={toggleSearch}
        >
          <Close />
        </button>
        <div className="container">
          <Search booking_id={data.contentfulHotel.booking_id} />
        </div>
      </div>
      <button
        className="modal-backdrop"
        aria-label={intl.formatMessage({ id: "close" })}
        onClick={toggleSearch}
      ></button>
    </div>
  );
};

export default BookModalHotel;
