import React, { useContext } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";

import Context from "./context";

const FeaturedOffer = ({ offer }) => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const allPages = data.allContentfulPage.nodes;

  const {
    url: iconUrl,
    details: {
      image: { width: iconWidth, height: iconHeight },
    },
  } = offer.icon.file;

  function getParentSlug() {
    return allPages.find((page) => page.category === "Offres").slug;
  }

  return (
    <div className="row flex-column-reverse flex-md-row">
      <div className="col-md-6 col-xl-5 offset-xl-1 mt-6 mt-md-0">
        <Link
          className={`offer offer-featured offer-${offer.color}`}
          to={`/${intl.locale}/${getParentSlug()}/${offer.slug}/`}
        >
          <div className="offer-heading">
            <p>{offer.discount}</p>
            {offer.icon && (
              <img
                src={iconUrl}
                width={iconWidth}
                height={iconHeight}
                alt={offer.title}
              />
            )}
          </div>
          <div className="offer-description">
            <h3>{offer.title}</h3>
            <p>{offer.short_description}</p>
          </div>
        </Link>
      </div>
      <div className="col-md-5 col-xl-4 offset-md-1">
        <h2 className="mt-0 mt-md-8">{offer.title}</h2>
        {offer.short_description && <p>{offer.short_description}</p>}
        <p className="mt-md-8">
          <Link to={`/${intl.locale}/${getParentSlug()}/${offer.slug}/`}>
            {intl.formatMessage({ id: "more" })}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default FeaturedOffer;
