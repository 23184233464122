import React, { useContext } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";
import Slider from "react-slick";

import Context from "./context";

const OffersSlider = () => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const allPages = data.allContentfulPage.nodes;

  const settings = {
    infinite: false,
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  function getParentSlug() {
    return allPages.find((page) => page.category === "Offres").slug;
  }

  return (
    <Slider className="slider slider-offers d-lg-none" {...settings}>
      {data.allContentfulOffre.nodes.map((node, i) => {
        const {
          url: iconUrl,
          details: {
            image: { width: iconWidth, height: iconHeight },
          },
        } = node.icon.file;

        return (
          <Link
            className={`offer offer-${node.color}`}
            to={`/${intl.locale}/${getParentSlug()}/${node.slug}/`}
            key={i}
          >
            <div className="offer-heading">
              {node.discount && <p>{node.discount}</p>}
              {node.icon && (
                <img
                  src={iconUrl}
                  width={iconWidth}
                  height={iconHeight}
                  alt={node.title}
                />
              )}
            </div>
            <div className="offer-description">
              <h3>{node.title}</h3>
              <p>{node.short_description}</p>
              <p className="link">{intl.formatMessage({ id: "more" })}</p>
            </div>
          </Link>
        );
      })}
    </Slider>
  );
};

export default OffersSlider;
