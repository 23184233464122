import React, { Component } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";

import Context from "./context";
import PreviousButton from "./previous-button";
import NextButton from "./next-button";

const withHook = (Component) => {
  return function WrappedComponent(props) {
    const intl = useIntl();
    return <Component {...props} intl={intl} />;
  };
};

class Slideshow extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    const { data } = this.context;
    const allPages = data.allContentfulPage.nodes;
    const { title, text, slides, intl } = this.props;

    const settings = {
      infinite: false,
      slidesToShow: 3,
      arrows: false,
      responsive: [
        {
          breakpoint: 575.98,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991.98,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1199.98,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 10000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
      ],
    };

    function getParentSlug(contentType) {
      return allPages.find((page) => {
        switch (contentType) {
          case "ContentfulActivite":
            return page.category === "Carnets de voyages";

          case "ContentfulService":
            return page.category === "Services";

          default:
            return null;
        }
      })?.slug;
    }

    return (
      <section className="section section-blue overflow-hidden">
        <div className="container">
          <h2>{title}</h2>
          {text && <p className="text-blue-dark">{text}</p>}
          <div className="row mt-6 mt-md-10">
            <div className="col-8 col-sm-10 col-lg-12 position-static">
              <LazyLoad>
                <PreviousButton onClick={this.previous} />
                <NextButton onClick={this.next} />
                <Slider
                  className="slider slider-thumbnails"
                  ref={(ref) => (this.slider = ref)}
                  {...settings}
                >
                  {slides.map((slide, i) => (
                    <Link
                      className="slider-thumbnails-item"
                      to={`/${intl.locale}/${getParentSlug(slide.__typename)}/${
                        slide.slug
                      }/`}
                      key={i}
                    >
                      {slide.thumbnail && (
                        <Img
                          className="slider-thumbnails-item-image"
                          fluid={slide.thumbnail.fluid}
                          alt={slide.title}
                        />
                      )}
                      <h3>{slide.title}</h3>
                    </Link>
                  ))}
                </Slider>
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withHook(Slideshow);
